import React, { useState, useEffect } from 'react'
import { Element } from 'react-scroll'
import dayjs from 'dayjs'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayJsUtils from '@date-io/dayjs'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextGridItem from 'components/TextGridItem'
import FullGridItem from 'components/FullGridItem'
import CenterTypography from 'components/CenterTypography'
import StepButton from 'components/StepButton'
import { STATES } from 'constants/states'
import { RACES } from 'constants/races'
import { SEXES } from 'constants/sexes'
import { REGEXES } from 'constants/regexes'
import { INSURANCEPROVIDERS } from 'constants/insuranceProviders'
import { generatePhoneCode } from 'features/registration/api/generatePhoneCode'
import { isPhoneCodeValid } from 'features/registration/api/isPhoneCodeValid'
import { usePushGADataLayer } from 'features/registration/hooks/data-layer'

function VerifyPhoneDialog({ isOpen, onClose, form, updateField }) {
  const [code, setCode] = useState('')
  const [sent, setSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isIncorrect, setIsIncorrect] = useState(false)
  
  usePushGADataLayer('Prescreening')

  useEffect(() => {
    setCode('')
    setSent(false)
    updateField('code', '')
  }, [form.phone])

  useEffect(() => {
    const phone = form.phone

    if (isOpen && !sent) {
      const loadGeneratePhoneCode = async () => {
        await generatePhoneCode(phone)
        setSent(true)
      }

      loadGeneratePhoneCode()
    }
  }, [isOpen])

  useEffect(() => {
    setIsIncorrect(false)
  }, [code])

  const onVerify = async () => {
    setIsLoading(true)

    const response = await isPhoneCodeValid(form.phone, code)

    setIsLoading(false)

    if (response.success) {
      updateField('code', code)
      onClose()
    } else {
      setCode('')
      setIsIncorrect(true)
    }
  }

  const isValid = RegExp(/^\d{4}$/).test(code)

  return (
    <Dialog fullWidth maxWidth='xs' open={isOpen} onClose={onClose}>
      <DialogTitle>Phone Verification</DialogTitle>

      <DialogContent>
        <DialogContentText>
          A code has been sent to your phone. Please enter that code here to verify.
        </DialogContentText>

        <TextField
          label='Code'
          variant='outlined'
          type='text'
          fullWidth
          placeholder='A four digit code'
          value={code}
          autoFocus
          onChange={(e) => setCode(e.target.value)}
          error={isIncorrect || (!!code && !isValid)}
          helperText={
            (isIncorrect && 'The code you entered was incorrect, please try again') ||
            (code && !isValid && 'Invalid') ||
            ' '
          }
        />

        <Button
          disabled={!isValid || isLoading}
          variant='contained'
          color='primary'
          style={{ height: '100%' }}
          fullWidth
          size='large'
          onClick={() => onVerify()}
        >
          Verify
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default function BasicInformation({ index, form, updateField }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const isRegexValid = (name) => {
    return RegExp(REGEXES[name]).test(form[name])
  }

  const onDateChange = (value) => {
    const monthString = `0${value.$M + 1}`.slice(-2)
    const dayString = `0${value.$D}`.slice(-2)
    const dateString = `${value.$y}-${monthString}-${dayString}`

    updateField('dateOfBirth', dateString)
  }

  const renderTextField = ({ name, label, placeholder }) => {
    const value = form[name]
    const isValid = isRegexValid(name)

    return (
      <TextField
        label={label}
        variant='outlined'
        type='text'
        fullWidth
        placeholder={placeholder}
        value={value}
        onChange={(e) => updateField(name, e.target.value)}
        error={!!value && !isValid}
        helperText={value && !isValid && 'Invalid'}
      />
    )
  }

  const renderSelect = ({ name, label, list, getValue = (x) => x, getName = (x) => x }) => {
    return (
      <FormControl variant='outlined' fullWidth>
        <InputLabel>{label}</InputLabel>
        <div>
          <Select label={label} fullWidth value={form[name]} onChange={(e) => updateField(name, e.target.value)}>
            {list.map((x) => (
              <MenuItem key={getValue(x)} value={getValue(x)}>
                {getName(x)}
              </MenuItem>
            ))}
          </Select>
        </div>
      </FormControl>
    )
  }

  const isChild = form.dateOfBirth && dayjs().diff(dayjs(form.dateOfBirth), 'y', true) < 18
  const disabled =
    isChild ||
    Object.keys(REGEXES)
      .map((x) => isRegexValid(x))
      .some((x) => !x)

  return (
    <Element name={index}>
      <div>
        <VerifyPhoneDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          form={form}
          updateField={updateField}
        />
        <Grid container direction='column' justifyContent='flex-start' alignItems='center'>
          <TextGridItem>
            <CenterTypography variant='h4' color='primary'>
              Tell us about the patient.
            </CenterTypography>
          </TextGridItem>

          <FullGridItem>
            {renderTextField({
              name: 'firstName',
              label: 'First Name',
            })}
          </FullGridItem>

          <FullGridItem>
            {renderTextField({
              name: 'middleInitial',
              label: 'Middle Initial',
            })}
          </FullGridItem>

          <FullGridItem>
            {renderTextField({
              name: 'lastName',
              label: 'Last Name',
            })}
          </FullGridItem>

          <FullGridItem>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <DatePicker
                minDate='1900-01-01'
                disableFuture
                openTo='year'
                format='MM/DD/YYYY'
                label='Date of Birth'
                views={['year', 'month', 'date']}
                inputVariant='outlined'
                fullWidth
                value={form.dateOfBirth}
                onChange={(v) => onDateChange(v)}
                error={isChild}
                helperText={isChild && 'Must be at least 18 years old'}
              />
            </MuiPickersUtilsProvider>
          </FullGridItem>

          <FullGridItem>
            <Grid container>
              <Grid item sm={8} xs={12}>
                {renderTextField({
                  name: 'phone',
                  label: 'Cell Phone Number',
                  placeholder: '555-555-5555',
                })}
              </Grid>
              <Grid item sm={4} xs={12}>
                <Button
                  disabled={!isRegexValid('phone') || !!form.code}
                  variant='contained'
                  color='primary'
                  style={{ height: 56 }}
                  fullWidth
                  size='large'
                  onClick={() => setIsDialogOpen(true)}
                >
                  {form.code ? '✓' : 'Validate'}
                </Button>
              </Grid>
            </Grid>
          </FullGridItem>

          <FullGridItem>
            {renderSelect({
              name: 'race',
              label: 'Race/Ethnicity',
              list: RACES,
            })}
          </FullGridItem>

          <FullGridItem>
            {renderSelect({
              name: 'sex',
              label: 'Sex',
              list: SEXES,
            })}
          </FullGridItem>

          <FullGridItem>
            {renderSelect({
              name: 'insuranceProvider',
              label: 'Insurance Provider',
              list: INSURANCEPROVIDERS,
            })}
          </FullGridItem>

          <TextGridItem>
            <CenterTypography variant='h4' color='primary'>
              Address Information
            </CenterTypography>
          </TextGridItem>

          <FullGridItem>
            {renderTextField({
              name: 'address',
              label: 'Street Address',
            })}
          </FullGridItem>

          <FullGridItem>
            {renderTextField({
              name: 'city',
              label: 'City',
            })}
          </FullGridItem>

          <FullGridItem>
            {renderSelect({
              name: 'state',
              label: 'State',
              list: STATES,
              getValue: (x) => x.abbreviation,
              getName: (x) => x.name,
            })}
          </FullGridItem>

          <FullGridItem>
            {renderTextField({
              name: 'zip',
              label: 'Zip Code',
            })}
          </FullGridItem>

          <Grid item>
            <StepButton disabled={disabled} form={form} updateField={updateField} />
          </Grid>
        </Grid>
      </div>
    </Element>
  )
}
