export const REGEXES = {
  firstName: /^.+$/,
  middleInitial: /^.{0,1}$/,
  lastName: /^.+$/,
  dateOfBirth: /^\d{4}-\d{2}-\d{2}$/,
  phone: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  race: /^.+$/,
  sex: /^.+$/,
  insuranceProvider: /^.+$/,
  address: /^.+$/,
  city: /^.+$/,
  state: /^.+$/,
  zip: /^\d+$/,
}
