import { Routes, Route, Navigate } from 'react-router-dom'
import AcceptPage from 'features/emailChange/routes/AcceptPage'
import RejectPage from 'features/emailChange/routes/RejectPage'
import VerificationPage from 'features/verification/routes/VerificationPage'
import RegistrationPage from 'features/registration/routes/RegistrationPage'
import VerifyEmailPage from 'features/verify-email/routes/Page'
import ScheduleAppointmentPage from 'features/schedule-appointment/routes/Page'
import AppointmentScheduledPage from 'features/schedule-appointment/routes/AppointmentScheduledPage'
import AppointmentNotScheduledPage from 'features/schedule-appointment/routes/AppointmentNotScheduledPage'

export function AppRoutes() {
  return (
    <Routes>
      <Route path='registration' element={<RegistrationPage />} />
      <Route path='/' element={<Navigate replace to='registration' />} />
      <Route path='verification' element={<VerificationPage />} />
      <Route path='accept' element={<AcceptPage />} />
      <Route path='reject' element={<RejectPage />} />
      <Route path='verify-email' element={<VerifyEmailPage />} />
      <Route path='schedule-appointment' element={<ScheduleAppointmentPage />} />
      <Route path='appointment-scheduled' element={<AppointmentScheduledPage />} />
      <Route path='appointment-not-scheduled' element={<AppointmentNotScheduledPage />} />
    </Routes>
  )
}
