import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

let theme = createTheme({
  palette: {
    primary: {
      main: '#283e73',
    },

    secondary: {
      main: '#a75861',
    },

    error: {
      main: '#9c3144',
    },

    success: {
      main: '#007d57',
    },
    warning: {
      main: '#d2a517',
    },
    text: {
      primary: '#283e73',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Oswald', 'Open Sans'].join(','),
  },

  height: '100%',
})
theme = responsiveFontSizes(theme)

export default theme
