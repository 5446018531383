import React, { useState } from 'react'
import { Element } from 'react-scroll'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import CenterTypography from 'components/CenterTypography'
import StepButton from 'components/StepButton'
import TextGridItem from 'components/TextGridItem'
import FullGridItem from 'components/FullGridItem'
import { usePushGADataLayer } from 'features/registration/hooks/data-layer'

export default function Agreement({ index, form, updateField }) {
  const [hasAgreed, setHasAgreed] = useState(false)
  
  usePushGADataLayer('Security Questions')

  const Document = ({ name, type }) => {
    var agreement = form.agreements.find((a) => a.type === type)

    if (!agreement)
      return (
        <FullGridItem>
            <Button variant='contained' color='primary' fullWidth>
              Loading...
            </Button>
        </FullGridItem>
      )

    return (
      <FullGridItem>
        <a href={`${process.env.REACT_APP_BLOB_URL}${agreement.fileName}`} target='_blank'>
          <Button variant='contained' color='primary' fullWidth>
            {name}
          </Button>
        </a>
      </FullGridItem>
    )
  }

  return (
    <Element name={index}>
      <div>
        <Grid container direction='column' justifyContent='flex-start' alignItems='center' spacing={2}>
          <TextGridItem>
            <CenterTypography variant='h4' color='primary'>
              Please read our policies and agree below.
            </CenterTypography>
          </TextGridItem>

          <Document name='Terms of Use' type='Mainstreet Family Urgent Care Terms and Conditions' />
          <Document name='Privacy Policy' type='Rural Urgent Care Online Privacy Policy' />
          <Document name='Wellness Snapshot Terms of Use' type='Snapshot Terms of Use' />

          <Grid item>
            <FormControlLabel
              control={<Checkbox checked={hasAgreed} onChange={() => setHasAgreed(!hasAgreed)} color='primary' />}
              label={
                <CenterTypography variant='body1' color='primary'>
                  I agree to the Terms of Use, Privacy Policy, and Wellness Snapshot Terms of Use.
                </CenterTypography>
              }
            />
          </Grid>

          <Grid item>
            <StepButton disabled={!hasAgreed} form={form} updateField={updateField} nextText='Submit' />
          </Grid>
        </Grid>
      </div>
    </Element>
  )
}
