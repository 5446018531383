import { apiClient } from 'lib/ky'
import { useMutation } from 'react-query'

const verifyEmail = async (id, code) => await apiClient.post(`Registration/VerifyEmail`, { json: { id, code } }).json()

export const useVerifyEmail = () => {
  return useMutation(({ userId, code }) => verifyEmail(userId, code))
}

const sendEmailCode = async (id) => await apiClient.post(`Registration/SendEmailCode`, { json: { id } }).json()

export const useResendCode = () => {
  return useMutation(({ userId }) => sendEmailCode(userId))
}
