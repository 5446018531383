import React, { useState } from 'react'
import { TextField, Typography, Grid, Button } from '@material-ui/core'
import FullGridItem, { FullGridContainer } from 'components/FullGridItem'
import TextGridItem from 'components/TextGridItem'
import CenterTypography from 'components/CenterTypography'
import { useVerifyEmail, useResendCode } from '../api/verify-email'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

export default function ({}) {
  const [code, setCode] = useState('')
  const [error, setError] = useState(false)
  const { mutate, data, isError } = useVerifyEmail()
  const { mutate: mutateResendCode, data: dataResendCode, isError: isErrorResendCode } = useResendCode()

  const [searchParams] = useSearchParams()
  const userId = searchParams.get('id')

  useEffect(() => {
    if (!userId) window.location = '//mainstreetfamilycare.com'
  }, [])

  const checkCode = () => {
    setError(false)
    console.log('check code', code)
    mutate(
      { userId, code },
      {
        onError: (err) => {},
        onSuccess: (data) => {
          if (data.status === 'succeeded') {
            window.location = `/schedule-appointment${window.location.search}`
          }

          if (data.status === 'failed') {
            setError(true)
            setCode('')
          }
        },
      }
    )
  }

  const resendCode = () => {
    console.log('re-send code', code)
    mutateResendCode(
      { userId },
      {
        onError: (err) => {},
        onSuccess: (data) => {
        },
      }
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <Grid container direction='column' justifyContent='flex-start' alignItems='center'>
        <TextGridItem sm={8}>
          <CenterTypography variant='body1' color='primary'>
            You will receive a confirmation email indicating we have received your registration. If you have not
            received the confirmation email within 10 minutes of submitting this registration form, please contact us
            here: <a href='https://www.mainstreetfamilycare.com/home-page/contact-us/'>Contact Us</a>
          </CenterTypography>
        </TextGridItem>

        <TextGridItem sm={8}>
          <CenterTypography variant='body1' color='primary'>
            Enter the code from your email here:
          </CenterTypography>
        </TextGridItem>

        <FullGridItem>
          <TextField
            label='Enter code'
            variant='outlined'
            fullWidth
            value={code}
            onChange={(e) => setCode(e.target.value)}
            error={error}
            helperText={error && 'Invalid code'}
          />
        </FullGridItem>

        <FullGridContainer justifyContent='space-between'>
          <Button variant='contained' onClick={resendCode}>
            Re-send code
          </Button>
          <Button variant='contained' color='primary' disabled={code.length < 4} onClick={checkCode}>
            Submit
          </Button>
        </FullGridContainer>

        <FullGridItem></FullGridItem>

        {/* <Grid item>
          <StepButton disabled={disabled} form={form} updateField={onNext} removeBackButton />
        </Grid> */}
      </Grid>
    </div>
  )
}
