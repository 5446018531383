import React from 'react'
import Grid from '@material-ui/core/Grid'

export default function FullGridItem({ children }) {
  return (
    <Grid item style={{ margin: 5, width: '100%', maxWidth: 350 }}>
      {children}
    </Grid>
  )
}

export function FullGridContainer({ children, justifyContent, alignContent, alignItems }) {
  return (
    <Grid
      container
      style={{ margin: 5, width: '100%', maxWidth: 350 }}
      justifyContent={justifyContent}
      alignContent={alignContent}
      alignItems={alignItems}
    >
      {children}
    </Grid>
  )
}
