import React, { useEffect } from 'react'
import { Element } from 'react-scroll'
import Grid from '@material-ui/core/Grid'
import TextGridItem from 'components/TextGridItem'
import CenterTypography from 'components/CenterTypography'
import { useRegisterPatient } from 'features/registration/api/register'
import { usePushGADataLayer } from 'features/registration/hooks/data-layer'

export default function Submit({ index, form }) {
  const { mutate, data, isError } = useRegisterPatient()
  
  usePushGADataLayer('Agreement')

  useEffect(() => {
    mutate(form, {
      onError: (err) => {
        
      },
      onSuccess: (data) => {
        window.location.href = `/verify-email${window.location.search || '?a=1'}&id=${data.id}`
      },
    })
  }, [])

  return (
    <Element name={index}>
      <div>
        <Grid container direction='column' justifyContent='flex-start' alignItems='center' spacing={2}>

          <TextGridItem>
            <CenterTypography variant='h4' color='primary'>
              {data ? (
                'Submitted!'
              ) : isError ? (
                <React.Fragment>
                  Sorry, registration failed. Please&nbsp;
                  <a href='https://www.mainstreetfamilycare.com/contact-us/' target='_blank' rel='noopener'>
                    contact us
                  </a>.
                </React.Fragment>
              ) : (
                'Processing...'
              )}
            </CenterTypography>
          </TextGridItem>
        </Grid>
      </div>
    </Element>
  )
}
