import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { useAcceptEmailChange } from '../api/accept'
import { useSearchParams } from 'react-router-dom'
import { BigMessageText } from 'components/BigMessageText'

export default function AcceptPage(form) {
  const [searchParmas] = useSearchParams()
  const acceptId = searchParmas.get('id')
  const { mutate, data, isError } = useAcceptEmailChange()

  useEffect(() => {
    mutate(acceptId, {
      onError: (err) => {
        
      },
      onSuccess: (data) => {
        
        setInterval(() => (window.location.href = data.link), 3000)
      },
    })
  }, [])

  if (data) {
    return <BigMessageText>Your email has been changed! Please login with your newly set email address!</BigMessageText>
  }

  if (isError) {
    return (
      <>
        <BigMessageText>Sorry, the email could not be changed.</BigMessageText>
        <Typography color='primary' variant='h4'>
          Please{' '}
          <a href='https://www.mainstreetfamilycare.com/contact-us/' target='_blank' rel='noopener'>
            contact us.
          </a>
        </Typography>
      </>
    )
  }

  return <BigMessageText>Processing...</BigMessageText>
}
