import React from 'react'
import CenterTypography from 'components/CenterTypography'
import ScheduleAppointment from '../components/schedule-appointment'

export default function Page() {
  
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <img src={process.env.PUBLIC_URL + '/Logo.png'} alt='mainstreetLogo' />
      </div>

      <CenterTypography variant='h4' color='primary'>
        Thank You! Your Primary Care Portal Account has been created.
      </CenterTypography>

      <ScheduleAppointment />
    </div>
  )
}
