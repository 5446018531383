import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { useRejectEmailChange } from '../api/reject'
import { useSearchParams } from 'react-router-dom'
import { BigMessageText } from 'components/BigMessageText'

export default function RejectPage(form) {
  const [searchParams] = useSearchParams()
  const rejectId = searchParams.get('id')
  const { mutate, data, isError } = useRejectEmailChange()

  useEffect(() => {
    mutate(rejectId, {
      onError: (err) => {
        
      },
      onSuccess: (data) => {
        
        setInterval(() => (window.location.href = data.link), 3000)
      },
    })
  }, [])

  if (data) {
    return (
      <BigMessageText>
        We've cancelled your request to change your account's email! Please continue to login with the same email.
      </BigMessageText>
    )
  }

  if (isError) {
    return (
      <>
        <BigMessageText>Sorry, the request to cancel the email change has failed.</BigMessageText>
        <Typography color='primary' variant='h4'>
          Please{' '}
          <a href='https://www.mainstreetfamilycare.com/contact-us/' target='_blank' rel='noopener'>
            contact us.
          </a>
        </Typography>
      </>
    )
  }

  return <BigMessageText>Processing...</BigMessageText>
}
