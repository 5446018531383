import { apiClient } from 'lib/ky'
import { useQuery, useMutation } from 'react-query'

const timeout = 30000

export const getLocations = async () => await apiClient.get(`registration/locations`).json()

export const useLocations = () => {
  return useQuery(['locations'], () => getLocations(), {
    cacheTime: 'Infinity',
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
}

export const getUserInfo = async (id) => await apiClient.get(`registration/user/${id}`).json()

export const useUserInfo = (id) => {
  return useQuery(['user', {id}], () => getUserInfo(id), {
    cacheTime: 'Infinity',
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
}

const recommendSlots = async (locationId, dates) => await apiClient.post(`Registration/recommendSlots`, { json: { locationId, dates } }).json()

export const useRecommendSlots = () => {
  return useMutation(({ locationId, dates }) => recommendSlots(locationId, dates))
}

const scheduleAppointment = async (id, slotId) => await apiClient.post(`Registration/scheduleAppointment`, { json: { id, slotId }, timeout }).json()

export const useScheduleAppointment = () => {
  return useMutation(({ userId, slotId }) => scheduleAppointment(userId, slotId))
}
