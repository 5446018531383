import React, { useEffect } from 'react'
import CenterTypography from 'components/CenterTypography'
import ScheduleAppointment from '../components/schedule-appointment'
import FullGridItem, { FullGridContainer } from 'components/FullGridItem'
import { Button, Grid } from '@material-ui/core'

const redirect = () => {
  window.location = `//mainstreetfamilycare.com/pc-registration-submitted${window.location.search}`
}

export default function Page() {
  
  useEffect(() => {
    setTimeout(redirect, 30000)
  }, [])

  return (
    <div>
      <div style={{ textAlign: 'center' }}></div>

      <Grid container direction='column' justifyContent='flex-start' alignItems='center' spacing={3}>
        <Grid item>
          <img src={process.env.PUBLIC_URL + '/Logo.png'} alt='mainstreetLogo' />
        </Grid>
        <Grid item>
          <CenterTypography variant='h4' color='primary'>
            Thanks for signing up!
          </CenterTypography>
        </Grid>

        <Grid item>
          <CenterTypography variant='h5'>
            Don't forget to login and schedule your first appointment with us.
          </CenterTypography>
        </Grid>

        <Grid item>
          <Button onClick={redirect} color='primary' variant='contained'>
            Ok
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
