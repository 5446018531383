import React from 'react'
import Button from '@material-ui/core/Button'

export default function StepButton({ disabled, form, updateField, removeBackButton, nextText = 'Next' }) {
  const activeStep = form.activeStep

  const goToPreviousStep = () => updateField('activeStep', activeStep - 1)

  const goToNextStep = () => updateField('activeStep', activeStep + 1)

  return (
    <div style={{ marginTop: '2vh' }}>
      {!removeBackButton &&
        <Button
          variant='outlined'
          color='inherit'
          style={{ marginRight: 8 }}
          onClick={() => goToPreviousStep()}>
          Back
        </Button>
      }
      
      <Button
        disabled={!!disabled}
        variant='contained'
        color='primary'
        onClick={() => goToNextStep()}>
        {nextText}
      </Button>
    </div>
  )
}
