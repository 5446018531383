import React from 'react'
import CenterTypography from 'components/CenterTypography'
import EmailVerification from '../components/email-verification'

export default function Page() {

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <img src={process.env.PUBLIC_URL + '/Logo.png'} alt='mainstreetLogo' />
      </div>

      <CenterTypography variant='h3' color='primary'>
        Almost done!
      </CenterTypography>

      <EmailVerification />
    </div>
  )
}
