import ky from 'ky'
import { useMutation } from 'react-query'

const apiClient = ky.create({
  retry: 0,
  timeout: 30000,
  prefixUrl: '/api'
})

const register = async (form) => await apiClient.post(`Registration/Register`, { json: { ...form } }, ).json()

export const useRegisterPatient = () => {
  return useMutation((form) => register(form))
}
