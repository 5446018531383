import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { useVerifyPatient } from '../api/verifyPatient'
import { useSearchParams } from 'react-router-dom'
import { BigMessageText } from 'components/BigMessageText'

export default function ValidationPage() {
  const [searchParmas] = useSearchParams()
  const userId = searchParmas.get('id')
  const { mutate, data, isError } = useVerifyPatient()

  useEffect(() => {
    mutate(userId, {
      onError: (err) => {
        
      },
      onSuccess: (data) => {
        if (data.status === 'Failed') {
          
        }
        if (data.status === 'Verified' || data.status === 'Already_Verified') {
          
          setInterval(() => (window.location.href = data.link), 3000)
        }
      },
    })
  }, [])

  const STATUSTEXT = {
    Verified: 'Your email is verified! Redirecting...',
    Already_Verified: 'Your email has already been verified. Redirecting...',
    Failed: `Sorry, verification failed. Please ${(
      <a href='https://www.mainstreetfamilycare.com/contact-us/' target='_blank' rel='noopener'>
        contact us
      </a>
    )}`,
  }

  if (data) {
    return <BigMessageText>{STATUSTEXT[data.status]}</BigMessageText>
  }

  if (isError) {
    return (
      <>
        <BigMessageText>Sorry, please refresh the page and try again.</BigMessageText>
        <Typography color='primary' variant='h4'>
          If you continue to see this error,{' '}
          <a href='https://www.mainstreetfamilycare.com/contact-us/' target='_blank' rel='noopener'>
            contact us.
          </a>
        </Typography>
      </>
    )
  }

  return <BigMessageText>Processing...</BigMessageText>
}
