import React, { useState } from 'react'
import { Element } from 'react-scroll'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextGridItem from 'components/TextGridItem'
import CenterTypography from 'components/CenterTypography'
import StepButton from 'components/StepButton'
import { usePushGADataLayer } from 'features/registration/hooks/data-layer'

export default function Prescreening({ index, form, updateField }) {
  usePushGADataLayer('Account Information')

  return (
    <Element name={index}>
      <div>
        <Grid container direction='column' justifyContent='flex-start' alignItems='center'>
          <TextGridItem>
            <CenterTypography variant='h4' color='primary'>
              Welcome to the MainStreet Patient Portal!
            </CenterTypography>
          </TextGridItem>

          <TextGridItem sm={5}>
            <CenterTypography>
              We are so excited you are interested in utilizing our Patient&nbsp;Portal! The Patient&nbsp;Portal allows
              you to participate in our health snapshot, potentially join our primary care program, and communicate with
              us via our patient portal!
            </CenterTypography>
          </TextGridItem>

          <TextGridItem>
            <CenterTypography>
              If you are interested in joining our Primary Care program, please note there are some conditions and
              criteria we are NOT able to treat and unfortunately mean you are not eligible for primary care with us at
              this time:
            </CenterTypography>
          </TextGridItem>

          <TextGridItem>
            <ul style={{ margin: 0 }}>
              <li>
                <Typography>Patients on 5 or more long term medications</Typography>
              </li>
              <li>
                <Typography>Patients that require insulin</Typography>
              </li>
              <li>
                <Typography>Patients on blood thinners - Coumadin, Xarelto, etc.</Typography>
              </li>
              <li>
                <Typography>
                  Patients that require controlled medications – Pain Medicines, Opioids, Sleep Aids, Tramadol, Xanax,
                  Ambien, Klonopin, etc.
                </Typography>
              </li>
              <li>
                <Typography>
                  Patients with neurological conditions like Parkinson's, Alzheimer's, Dementia, etc.
                </Typography>
              </li>
              <li>
                <Typography>Patients with psychiatric conditions like Bipolar Disorder, Schizophrenia, etc.</Typography>
              </li>
              <li>
                <Typography>Patients in need of home health or hospice</Typography>
              </li>
              <li>
                <Typography>
                  In Alabama, Blue Cross Patients with a BEG, PGX, or PGL Prefix (Blue Cross Blue Shield plans purchased
                  on the Health Insurance Exchange) are currently not eligible for Primary Care with us
                </Typography>
              </li>
            </ul>
          </TextGridItem>

          <Grid item>
            <StepButton form={form} updateField={updateField} />
          </Grid>
        </Grid>
      </div>
    </Element>
  )
}
