import React, { useEffect } from 'react'
import { Element } from 'react-scroll'

import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'

import FullGridItem from 'components/FullGridItem'
import CenterTypography from 'components/CenterTypography'
import TextGridItem from 'components/TextGridItem'
import StepButton from 'components/StepButton'
import { listSecurityQuestion } from 'features/registration/api/listSecurityQuestion'
import { usePushGADataLayer } from 'features/registration/hooks/data-layer'

export default function SecurityQuestions({ index, form, updateField }) {
  usePushGADataLayer('Basic Information')

  useEffect(() => {
    const loadSecurityQuestions = async () => {
      const response = await listSecurityQuestion()

      updateField('securityQuestions', response)
    }

    if (!form.securityQuestions) {
      loadSecurityQuestions()
    }
  }, [])

  const getFilteredSecurityQuestions = (i) => {
    const selected = form.securityAnswers.map((x) => x.id)
    selected.splice(i, 1)

    return form.securityQuestions.filter((x) => !selected.includes(x.id))
  }

  const selectSecurityQuestion = (i, id) => {
    form.securityAnswers[i].id = id

    updateField('securityAnswers', [...form.securityAnswers])
  }

  const updateSecurityAnswer = (i, value) => {
    form.securityAnswers[i].answer = value

    updateField('securityAnswers', [...form.securityAnswers])
  }

  const renderQuestions = () => {
    const renderSpacing = () => {
      return (
        <FullGridItem key={Math.random()}>
          <br />
        </FullGridItem>
      )
    }

    return [renderQuestion(0), renderSpacing(), renderQuestion(1), renderSpacing(), renderQuestion(2)]
  }

  const renderQuestion = (i) => {
    const displayNumber = i + 1
    const value = form.securityAnswers[i].answer

    return (
      <Grid key={displayNumber} container item direction='column' alignItems='center'>
        <FullGridItem>
          <FormControl variant='outlined' fullWidth>
            <InputLabel>Select Question #{displayNumber}</InputLabel>
            <Select
              label={`Select Question #${displayNumber}`}
              fullWidth
              value={form.securityAnswers[i].id}
              onChange={(e) => selectSecurityQuestion(i, e.target.value)}
            >
              {getFilteredSecurityQuestions(i).map((x) => (
                <MenuItem key={x.id} value={x.id}>
                  {x.question}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FullGridItem>

        <FullGridItem>
          <TextField
            label={`Question #${displayNumber} Answer`}
            variant='outlined'
            type='text'
            fullWidth
            value={value}
            onChange={(e) => updateSecurityAnswer(i, e.target.value)}
          />
        </FullGridItem>
      </Grid>
    )
  }

  if (!form.securityQuestions) {
    return (
      <Element name={index}>
        <Grid container direction='column' alignItems='center'>
          <CenterTypography variant='h4' color='primary'>
            Loading Security Questions...
          </CenterTypography>
        </Grid>
      </Element>
    )
  }

  const disabled = form.securityAnswers.flatMap((x) => Object.values(x)).some((x) => x === '')

  return (
    <Element name={index}>
      <div>
        <Grid container direction='column' justifyContent='flex-start' alignItems='center'>
          <TextGridItem>
            <CenterTypography variant='h4' color='primary'>
              Security Questions
            </CenterTypography>
          </TextGridItem>

          {renderQuestions()}

          <Grid item>
            <StepButton disabled={disabled} form={form} updateField={updateField} />
          </Grid>
        </Grid>
      </div>
    </Element>
  )
}
