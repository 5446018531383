import { useEffect } from 'react'

export const usePushGADataLayer = (formType) => {
    useEffect(() => {
      window.dataLayer = window.dataLayer || []
  
      const data = {
        event: 'formSubmission',
        formType: formType,
      }
  
      window.dataLayer.push(data)
      console.log('push data layer', data)
    }, [])
  }
  