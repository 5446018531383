import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { queryClient } from '../lib/react-query'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core'
import theme from '../theme'
import { BrowserRouter } from 'react-router-dom'

export const AppProvider = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>{children}</BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </ThemeProvider>
    </QueryClientProvider>
  )
}
