import React from 'react'
import { Typography } from '@material-ui/core'

export default function CenterTypography({ children, variant, color }) {
  return (
    <Typography align='center' variant={variant} color={color}>
      {children}
    </Typography>
  )
}
