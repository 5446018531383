import React from 'react'
import CenterTypography from 'components/CenterTypography'
import FormStepper from '../components/FormStepper'

export default function RegistrationPage() {
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <img src={process.env.PUBLIC_URL + '/Logo.png'} alt='mainstreetLogo' />
      </div>

      <CenterTypography variant='h3' color='primary'>
        Patient Portal Registration
      </CenterTypography>

      <FormStepper />
    </div>
  )
}
