import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { Element } from 'react-scroll'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FullGridItem from 'components/FullGridItem'
import CenterTypography from 'components/CenterTypography'
import StepButton from 'components/StepButton'
import TextGridItem from 'components/TextGridItem'
import { getPasswordRegex } from 'features/registration/api/getPasswordRegex'
import { isUsernameValid } from 'features/registration/api/isUsernameValid'
import { registrationStarted } from 'features/registration/api/registrationStarted'
import { useIndexOnActive as useActiveAgreements } from 'features/registration/api/agreements'

export default function AccountInformation({ index, form, updateField }) {
  const [passwordRegex, setPasswordRegex] = useState('')
  const [passwordComplexityText, setPasswordComplexityText] = useState('')
  const [isEmailAvailable, setIsEmailAvailable] = useState(false)
  const [isCheckingEmail, setIsCheckingEmail] = useState(false)
  const [debouncers, setDebouncers] = useState({})
  const [searchParams] = useSearchParams()

  const registeringClinic = searchParams.get('clinic')
  const providedEmail = searchParams.get('email')

  useEffect(() => {
    const loadPasswordRegex = async () => {
      const response = await getPasswordRegex()

      setPasswordRegex(response.regex)
      setPasswordComplexityText(response.text)
    }

    if (!form.passwordRegex) {
      loadPasswordRegex()
    }

    setPasswordRegex(form.passwordRegex)
    setPasswordComplexityText(form.passwordComplexityText)
    setIsEmailAvailable(form.isEmailAvailable)
  }, [])

  const checkIsEmailAvailable = async (email) => {
    setIsEmailAvailable(false)
    setIsCheckingEmail(true)

    if (debouncers.email) {
      debouncers.email.cancel()
    }

    const debouncer = _.debounce(() => {
      isUsernameValid(email).then((result) => {
        setIsEmailAvailable(result)
        setIsCheckingEmail(false)
      })
    }, 2000)

    debouncer()

    setDebouncers({ email: debouncer })
  }

  const isEmailRegexValid = (value) => {
    return RegExp(/^.+@.+\..+$/).test(value)
  }
  const onEmailChange = (value) => {
    const trimmed = value && value.trim()
    updateField('email', trimmed)

    if (isEmailRegexValid(trimmed)) {
      checkIsEmailAvailable(trimmed)
    }
  }
  useEffect(() => updateField('isEmailAvailable', isEmailAvailable), [isEmailAvailable])
  useEffect(() => updateField('passwordRegex', passwordRegex), [passwordRegex])
  useEffect(() => updateField('passwordComplexityText', passwordComplexityText), [passwordComplexityText])
  useEffect(() => {
    if (providedEmail) {
      onEmailChange(providedEmail)
    }
  }, [])  

  useActiveAgreements({
    onSuccess: (data) => {
      console.log({agreements: data})
      const { agreements } = data
  
      updateField('agreements', agreements)
    }
  })

  // useEffect(async () => {
  //   const res = await indexActiveAgreements()
  //   const { agreements } = res

  //   updateField('agreements', agreements)
  // }, [])  

  if (!form.passwordRegex) {
    return (
      <Element name={index}>
        <Grid container direction='column' alignItems='center'>
          <CenterTypography variant='h4' color='primary'>
            Loading...
          </CenterTypography>
        </Grid>
      </Element>
    )
  }

  const onNext = (key, value) => {
    registrationStarted(form.email)

    updateField(key, value)
  }

  const isEmailFormatted = isEmailRegexValid(form.email)
  const isPasswordValid = form.password.length > 0 && RegExp(form.passwordRegex).test(form.password)
  const doesConfirmedPasswordMatch = form.password === form.confirmedPassword
  const disabled = !isEmailFormatted || !isEmailAvailable || !isPasswordValid || !doesConfirmedPasswordMatch

  return (
    <Element name={index}>
      <div>
        <Grid container direction='column' justifyContent='flex-start' alignItems='center'>
          <FullGridItem>
            <TextField
              label='Email'
              variant='outlined'
              type='text'
              fullWidth
              placeholder='email@address.com'
              value={form.email}
              onChange={(e) => onEmailChange(e.target.value)}
              error={!!form.email && (!isEmailFormatted || (!isCheckingEmail && !isEmailAvailable))}
              helperText={
                form.email &&
                ((!isEmailFormatted && 'Email is invalid') ||
                  (isCheckingEmail && 'Checking') ||
                  (isEmailAvailable && 'Email is available') ||
                  'Email is unavailable')
              }
            />
          </FullGridItem>

          <FullGridItem>
            <TextField
              label='Password'
              variant='outlined'
              type='password'
              fullWidth
              value={form.password}
              onChange={(e) => updateField('password', e.target.value)}
              error={!!form.password && !isPasswordValid}
              helperText={form.password && !isPasswordValid && 'Password is invalid'}
            />
          </FullGridItem>

          <FullGridItem>
            <TextField
              label='Confirm Password'
              variant='outlined'
              type='password'
              fullWidth
              value={form.confirmedPassword}
              onChange={(e) => updateField('confirmedPassword', e.target.value)}
              error={!!form.confirmedPassword && !doesConfirmedPasswordMatch}
              helperText={form.confirmedPassword && !doesConfirmedPasswordMatch && 'Does not match password'}
            />
          </FullGridItem>

          <TextGridItem>
            <CenterTypography color='primary'>{form.passwordComplexityText}</CenterTypography>
          </TextGridItem>

          <Grid item>
            <StepButton disabled={disabled} form={form} updateField={onNext} removeBackButton />
          </Grid>
        </Grid>
      </div>
    </Element>
  )
}
