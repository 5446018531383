import React, { useState } from 'react'
import { Stepper, Step, StepLabel, StepContent } from '@material-ui/core'
import AccountInformation from './formSteps/0-accountInformation/AccountInformation'
import Prescreening from './formSteps/1-preScreening/Prescreening'
import BasicInformation from './formSteps/2-basicInformation/BasicInformation'
import SecurityQuestions from './formSteps/3-securityQuestions/SecurityQuestions'
import Agreement from './formSteps/4-agreement/Agreement'
import Submit from './formSteps/5-submit/Submit'

export default function FormStepper() {
  const steps = [
    'Account Information',
    'Prescreening',
    'Basic Information',
    'Security Questions',
    'Agreement',
    'Submit',
  ]

  const [form, setForm] = useState({
    activeStep: 0,

    email: '',
    isEmailAvailable: false,
    password: '',
    confirmedPassword: '',

    firstName: '',
    middleInitial: '',
    lastName: '',
    dateOfBirth: null,
    phone: '',
    code: '',
    race: '',
    sex: '',
    insuranceProvider: '',

    address: '',
    city: '',
    state: 'AL',
    zip: '',

    securityAnswers: [
      { id: '', answer: '' },
      { id: '', answer: '' },
      { id: '', answer: '' },
    ],

    agreements: [],

    passwordRegex: '',
    passwordComplexityText: '',
    securityQuestions: null,
  })

  const updateField = (key, value) => {
    setForm({ ...form, [key]: value })
  }

  const getStepComponent = (index) => {
    const parameters = { index: index.toString(), form, updateField }

    switch (index) {
      case 0:
        return <AccountInformation {...parameters} />
      case 1:
        return <Prescreening {...parameters} />
      case 2:
        return <BasicInformation {...parameters} />
      case 3:
        return <SecurityQuestions {...parameters} />
      case 4:
        return <Agreement {...parameters} />
      case 5:
        return <Submit {...parameters} />
      default:
        return 'Unknown Step'
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <Stepper activeStep={form.activeStep} orientation='vertical'>
        {steps.map((name, index) => (
          <Step key={name}>
            <StepLabel>{name}</StepLabel>
            <StepContent>{getStepComponent(index)}</StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}
