import { QueryClient } from 'react-query'

const queryClient = new QueryClient()

if (window.Cypress) {
  // only during testing
  window.queryClient = queryClient
}

export { queryClient }
