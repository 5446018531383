import React, { useState } from 'react'
import {
  TextField,
  Typography,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import FullGridItem, { FullGridContainer } from 'components/FullGridItem'
import TextGridItem from 'components/TextGridItem'
import CenterTypography from 'components/CenterTypography'
import { useLocations, useUserInfo, useRecommendSlots, useScheduleAppointment } from '../api/schedule-appointment'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { DayPicker } from 'react-day-picker'
import dayjs from 'dayjs'
import 'react-day-picker/dist/style.css'

export default function ({}) {
  const [code, setCode] = useState('')
  const [error, setError] = useState(false)
  const [status, setStatus] = useState('')
  const [locationId, setLocationId] = useState('')
  const [selectedAppointmentDate, setSelectedAppointmentDate] = useState('')
  const [selectedAppointment, setSelectedAppointment] = useState({
    date: '',
    time: '',
    availabilitySlotId: '',
  })
  const [days, setDays] = React.useState([])
  const {
    mutate: recommendSlots,
    data: dataRecommendSlots,
    isError: isErrorRecommendSlots,
    isLoading: isLoadingSlots,
  } = useRecommendSlots()

  const {
    mutate: scheduleAppointment,
    data: dataAppointment,
    isError: isErrorScheduleAppointment,
  } = useScheduleAppointment()

  const slots = dataRecommendSlots || []

  const { data: locationsData } = useLocations()

  const [searchParams] = useSearchParams()
  const userId = searchParams.get('id')

  useEffect(() => {
    if (!userId) window.location = '//mainstreetfamilycare.com'
  }, [])

  const { data: user } = useUserInfo(userId)

  useEffect(() => {
    if (user) setLocationId(user.locationId)
  }, [user])

  const checkCode = () => {
    setError(false)
    console.log('check code', code)
  }

  const getSlots = () => {
    console.log('get slots', code)

    recommendSlots(
      { locationId, dates: days },
      {
        onError: (err) => {},
        onSuccess: (data) => {
          console.log(data)
        },
      }
    )
  }

  const schedule = () => {
    console.log('schedule appt')
    setStatus('scheduling')

    scheduleAppointment(
      { userId, slotId: selectedAppointment.availabilitySlotId },
      {
        onError: (err) => {},
        onSuccess: (data) => {
          console.log({ scheduleAppointment: data })
          window.location = `/appointment-scheduled${window.location.search}`
        },
      }
    )
  }

  const skipScheduling = () => {
    window.location = `/appointment-not-scheduled${window.location.search}`
  }

  const handleCheckboxChange = (event) => {
    const appointmentDate = event.target.checked ? event.target.value : ''
    if (appointmentDate) {
      const availabilitySlotId = slots.find((s) => s.date === appointmentDate).id
      setSelectedAppointment({ date: appointmentDate, time: '', availabilitySlotId })
    } else setSelectedAppointment({ date: appointmentDate, time: '', availabilitySlotId: '' })

    setSelectedAppointmentDate(appointmentDate)
  }

  const handleAppointmentTimeChange = (event) => {
    const availabilitySlotId = event.target.value
    const slot = slots.find((s) => s.id === availabilitySlotId)
    setSelectedAppointment({ date: slot.date, time: '', availabilitySlotId })
    setSelectedAppointmentDate(slot.date)
    // dispatch(appointmentSlotUpdated(event.target.value))
    // const foundSlot = appointmentSlots.find((id) => id === availabilitySlotId)
    // if (foundSlot) {
    //   setSelectedAppointmentDate(foundSlot.date)
    // }
    // setActingSelectedAppointment({ availabilitySlotId })
  }

  const groupAppointmentsByDate = (slots) => {
    const dates = {}
    for (const slot of slots) {
      if (dates[slot.date]) dates[slot.date].push(slot)
      else dates[slot.date] = [slot]
    }
    return dates
  }

  const locations = locationsData || []

  /*
id
: 
"6990256a-56af-401d-9450-f2853547f9ec"
name
: 
"Yearly Physical/Wellness Visit"
visitTypeId
: 
"3d440fd2-88f3-452b-bea5-57736b8142ca"
*/

  const footer =
    days && days.length > 0 ? <p>You selected {days.length} day(s).</p> : <p>Please pick one or more days.</p>

  const today = dayjs()

  console.log(days)

  const appointmentDays = groupAppointmentsByDate(slots)
  console.log(appointmentDays)

  const appointmentOptions = Object.keys(appointmentDays).map((key) => ({ date: key, times: appointmentDays[key] }))

  const none = appointmentOptions.length === 0

  return (
    <div style={{ width: '100%' }}>
      <Grid container direction='column' justifyContent='flex-start' alignItems='center'>
        <TextGridItem sm={8}>
          <CenterTypography variant='h4' color='primary'>
            Now, to complete your registration, please schedule your initial physical!
          </CenterTypography>
        </TextGridItem>

        {/* <FullGridContainer justifyContent='space-around'>
          <FormControl>
            <InputLabel htmlFor='location'>Location</InputLabel>
            <Select
              native
              value={locationId}
              onChange={(e) => setLocationId(e.target.value)}
              inputProps={{
                name: 'location',
                id: 'location',
              }}
            >
              {locations.map((loc) => (
                <option key={loc.id} value={loc.id}>
                  {loc.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </FullGridContainer> */}

        <FullGridItem>
          <DayPicker
            mode='multiple'
            selected={days}
            onSelect={setDays}
            // footer={footer}
            disabled={{
              before: today.toDate(),
              after: today.add(1, 'year').toDate(),
            }}
          />
        </FullGridItem>

        <FullGridContainer justifyContent='space-around'>
          <Button variant='contained' color='primary' disabled={days.length === 0 || isLoadingSlots} onClick={getSlots}>
            See available time slots
          </Button>
        </FullGridContainer>

        {isLoadingSlots ? (
          'loading'
        ) : (
          <FullGridContainer alignItems='center'>
            {appointmentOptions.map((option) => (
              <>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        checked={selectedAppointmentDate === option.date}
                        onChange={handleCheckboxChange}
                        value={option.date}
                      />
                    }
                    label={dayjs(option.date).format('dddd, MMM Do')}
                  />
                </FormControl>
                <Select
                  native
                  onChange={handleAppointmentTimeChange}
                  // disabled={selectedAppointmentDate !== option.date}
                  value={selectedAppointment ? selectedAppointment.availabilitySlotId : ''}
                >
                  {option.times.map((appointmentTime) => (
                    <option value={appointmentTime.id} key={appointmentTime.id}>
                      {dayjs(appointmentTime.start).format('h:mm A')}
                    </option>
                  ))}
                </Select>
              </>
            ))}
            {none ? (
              <div>No availability found for the selected dates.</div>
            ) : (
              <FullGridContainer justifyContent='space-around'>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={!selectedAppointment.availabilitySlotId || status === 'scheduling'}
                  onClick={schedule}
                >
                  Schedule Appointment
                </Button>
              </FullGridContainer>
            )}
          </FullGridContainer>
        )}

        <FullGridContainer justifyContent='space-around'>
          <Button
            // variant='contained'
            color='secondary'
            disabled={status === 'scheduling'}
            onClick={skipScheduling}
          >
            Skip this for now
          </Button>
        </FullGridContainer>
      </Grid>
    </div>
  )
}
